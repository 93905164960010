module.exports = [
  {
    "top": 473,
    "left": 570
  },
  {
    "top": 336,
    "left": 361
  },
  {
    "top": 604,
    "left": 85
  },
  {
    "top": 597,
    "left": 820
  },
  {
    "top": 413,
    "left": 228
  },
  {
    "top": 587,
    "left": 929
  },
  {
    "top": 359,
    "left": 805
  },
  {
    "top": 181,
    "left": 123
  },
  {
    "top": 305,
    "left": 607
  },
  {
    "top": 527,
    "left": 712
  },
  {
    "top": 228,
    "left": 917
  },
  {
    "top": 111,
    "left": 303
  },
  {
    "top": 346,
    "left": 966
  },
  {
    "top": 221,
    "left": 409
  },
  {
    "top": 188,
    "left": 18
  },
  {
    "top": 181,
    "left": 805
  },
  {
    "top": 546,
    "left": 366
  },
  {
    "top": 133,
    "left": 585
  },
  {
    "top": 434,
    "left": 45
  },
  {
    "top": 92,
    "left": 955
  },
  {
    "top": 227,
    "left": 242
  },
  {
    "top": 65,
    "left": 457
  },
  {
    "top": 147,
    "left": 703
  },
  {
    "top": 518,
    "left": 196
  }
];
