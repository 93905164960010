import tingle from "tingle.js";
import Snowflakes from "magic-snowflakes";

import box_positions from "./box_positions";
import images from "../static/imgs/*.jpg";
import schummeln from "../static/schummeln.jpg";
import quotes from "./quotes";

function create_modal(image: any, text: string) {
  const modal = new tingle.modal({
    footer: false,
    stickyFooter: false,
    closeMethods: ["escape", "overlay"],
  });
  modal.setContent(
    `<img class="data-image" src="${image}" /><p class="quote">${text}</p>`
  );
  return modal;
}

function create_door_div(day: number, top: number, left: number) {
  const door = document.createElement("a");

  door.classList.add("door");
  door.textContent = day.toString();
  door.style.top = `${top}px`;
  door.style.left = `${left}px`;

  return door;
}

function should_unhide() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has("unhide");
}

const snowflakes = new Snowflakes({
  color: "#fff",
  minOpacity: 0.9,
});

snowflakes.start();

const date = new Date();
// const date = new Date(2023, 11, 7, 8);
const unhide = should_unhide();

if (date.getMonth() != 11 && unhide === false) return;

const calendar_div = document.getElementById("calendar")!;

box_positions.forEach((position, index) => {
  const day = index + 1;
  const door_div = create_door_div(day, position.top, position.left);

  var image = images[index + 1];
  var text = quotes[index];

  if (day > date.getDate() && unhide === false) {
    text = "Nana, geschummelt wird nicht";
    image = schummeln;
  } else if (index >= Object.keys(images).length) {
    text = "Da fehlt doch was ...";
    image = schummeln;
  }

  const modal = create_modal(image, text);
  modal
    .getContent()
    .querySelector(".data-image")
    .addEventListener("click", (ev) => {
      modal.close();
    });

  door_div.addEventListener("click", (ev) => {
    modal.open();
  });

  calendar_div.append(door_div);
});
