const quotes = [
  "Nichts bringt uns auf unserem Weg besser voran als eine Pause (Elizabeth Barrett Browning)",
  "Kein Medikament ersetzt ein Lächeln (deutsches Sprichwort)",
  "Zwei Herzen verstehen sich leichter als zwei Köpfe (Alexander Engel)",
  "Eine Radfahrt um die Welt beginnt mit dem ersten Tritt aufs Pedal (Scott Stoll)",
  "Der Mensch kennt seine Schwäche sowenig wie der Ochse seine Stärke (Sprichwort)",
  "Verschmutze nicht den Platz, an dem du gegessen hast! (Sprichwort)",
  "Phantasie ist der Versuchsballon, den man am allerhöchsten steigen lassen kann (Lauren Bacall)",
  "Eine Erzählung ohne Frau ist eine Maschine ohne Dampf (Anton Tschechow)",
  "Die Stoßstange ist aller Laster Anfang",
  "Nichts bringt uns auf unserem Weg besser voran als eine Pause (Elizabeth Barrett-Browning)",
  "Nicht Philosophen stellen die radikalsten Fragen, sondern Kinder (Hellmut Walters)",
  "Frohes Gemüt kann Schnee in Feuer verwandeln (Sprichwort)",
  "Wenn alle Tage im Jahr gefeiert würden, wäre Spiel so lästig wie Arbeit (William Shakespeare)",
  "Der wahrhaft Tapfere fürchtet kein Urteil, es sei denn das des eigenen Gewissens (Erich Mühsam)",
  "In der Freundschaft ist es besser zuzuhören, als Ratschläge zu erteilen (Carmen Sylva)",
  "Wir sitzen alle auf einer Kugel, und von der kann keiner runterspringen (Ulf Merbold)",
  "Der Spiegel offenbart dir nur, was du zu sehen bereit bist (Helga Schäferling)",
  "Ist man in kleinen Dingen nicht geduldig, bringt man die großen Vorhaben zum Scheitern (Konfuzius)",
  "Es ist jedem heilsam, sich auch einmal als Karikatur sehen zu können (Karl Gutzkow)",
  "Bildung beginnt mit Neugierde (Peter Bieri)",
  "Aber es gibt Niederlagen, die Siege sind; und Siege, verhängnisvoller als Niederlagen (Karl Liebknecht)",
  "Man empfängt Menschen nach dem Kleide und entlässt sie nach dem Verstand (Karl Simrock)",
  "Rutsch lieber mit dem Fuß aus als mit der Zunge (aus Bulgarien)",
  "Glück entsteht oft durch Aufmerksamkeiten in kleinen Dingen (Wilhelm Busch)"
]

module.exports = quotes;